import { IGroupedEstimate } from '../common/types/IGroupedEstimate';
import * as XLSX from 'xlsx';
import { HeadersMap } from './types';
import { IExportEstimate } from './interfaces';
import moment from 'moment';
import { IEstimatesFilter } from '../common/hooks/useLink';

export const EstimateToExcelHeader: HeadersMap<IExportEstimate> = {
  year: 'Год',
  quarter: 'Квартал',
  estimationDate: 'Дата оценки',
  vertical: 'Вертикаль',
  client: 'Клиент',
  managerName: 'Имя менеджера',
  managerSurname: 'Фамилия менеджера',
  managerMiddlename: 'Отчество менеджера',
  question: 'Вопрос',
  estimate: 'Оценка',
  comment: 'Комментарий',
};
export function formatEstimatesToExport(
  groupedEstimates: IGroupedEstimate[],
): IExportEstimate[] {
  return groupedEstimates
    .map((estimateGroup) => {
      return estimateGroup.details.map((estimate) => ({
        year: estimateGroup.year?.toString() || '',
        quarter: estimateGroup.quarter?.toString() || '',
        estimationDate: moment(estimate.createAt).format('DD/MM/YYYY'),
        vertical: estimateGroup.verticalTitle,
        client: estimateGroup.clientTitle,
        managerName: estimateGroup.managerName || '',
        managerSurname: estimateGroup.managerSurname || '',
        managerMiddlename: estimateGroup.managerMiddlename || '',
        question: estimate.question?.text || '',
        estimate: estimate.estimate?.toString() || '',
        comment: estimate.comment || '',
      }));
    })
    .flat();
}

export function exportToExcel<T extends object>(
  data: T[],
  fileName = 'export.xlsx',
  headersMap: HeadersMap<T> = {},
): void {
  if (!data || data.length === 0) {
    console.error('Данные отсутствуют или пусты.');
    return;
  }

  // Генерируем заголовки: если есть сопоставление, заменяем на пользовательские названия
  const originalKeys = Object.keys(data[0]) as [keyof T];
  const headers = originalKeys.map((key) => headersMap[key] || key);

  // Преобразуем массив объектов в формат, поддерживаемый XLSX
  const worksheetData = [
    headers, // Добавляем заголовки как первую строку
    ...data.map(
      (item) => originalKeys.map((key) => (item as any)[key]), // Используем оригинальные ключи для доступа к данным
    ),
  ];

  // Создаем рабочий лист
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Задаем ширину столбцов
  worksheet['!cols'] = headers.map(() => ({
    wch: 15, // Минимальная ширина столбца — 10 символов
  }));

  // Задаем высоту строки (только для заголовка)
  worksheet['!rows'] = [
    { hpx: 25 }, // Высота первой строки (заголовков) в пикселях
    ...Array(data.length).fill({ hpx: 40 }), // Остальные строки по 20 пикселей
  ];

  // Создаем рабочую книгу и добавляем в нее лист
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Лист1');

  // Экспортируем файл
  XLSX.writeFile(workbook, fileName);
}

export const generateEstimatesFilename = (
  filters: IEstimatesFilter,
  verticalName?: string,
): string => {
  const quarterString = filters.quarter ? `_${filters.quarter}q` : '';
  const yearString = filters.year ? `_${filters.year}` : '';
  const verticalString = verticalName ? `_${verticalName}` : '';
  const dateString = moment().utc().format('YYYYMMDD');

  return `${dateString}_nps_export_data${quarterString}${yearString}${verticalString}.xlsx`;
};

export const getPreviousQuarterAndYear = (): {
  quarter: number;
  year: number;
} => {
  const currentMonth = moment().month();
  const currentYear = moment().year();

  if (currentMonth < 3) {
    return { quarter: 4, year: currentYear - 1 };
  } else if (currentMonth < 6) {
    return { quarter: 1, year: currentYear };
  } else if (currentMonth < 9) {
    return { quarter: 2, year: currentYear };
  } else {
    return { quarter: 3, year: currentYear };
  }
};
